<template>
  <div>
    <v-btn
      v-if="!isReportFilter"
      @click="toggleFilterOnXs()"
      class="search-bar-toggle d-md-none"
      ><v-icon>mdi-dots-horizontal</v-icon></v-btn
    >
    <v-sheet
      :class="[
        !isReportFilter && 'books-filters-parent',
        !isReportFilter && showFilterOnXs && 'show',
        isReportFilter && 'pt-10 in-report-filter',
        showFilterOnXs ? 'show' : ''
      ]"
      outlined
    >
      <div v-if="!isReportFilter" :class="['px-6 pt-2']" style="height: 40px">
        <v-btn
          tile
          x-small
          @click="toggleFilterOnXs()"
          class="float-right ml-2 d-md-none"
          >Hide</v-btn
        >
        <v-btn tile x-small @click="resetFilter()" class="float-right ml-2"
          >Reset</v-btn
        >
        <v-btn tile x-small @click="addNewMember()" class="primary float-right"
          >Add new member</v-btn
        >
      </div>
      <div
        :class="['d-flex', 'align-center']"
        style="height: calc(100% - 50px)"
      >
        <v-row class="mx-0 px-4">
          <v-col
            v-if="!isReportFilter"
            xl="2"
            md="2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-text-field
              v-if="searchBy !== 'bloodType'"
              v-model="searchKey"
              clearable
              dense
              class="mx-2"
              append-icon="mdi-magnify"
              :label="getSearchLabel()"
            ></v-text-field>
            <v-select
              v-else
              class="mx-2"
              v-model="searchKey"
              :items="bloodTypes"
              :label="getSearchLabel()"
              append-icon="mdi-magnify"
              clearable
              dense
            ></v-select>
          </v-col>

          <v-col
            v-if="!isReportFilter"
            xl="2"
            md="2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-select
              dense
              class="mx-2"
              :items="searchByFilters"
              item-text="name"
              item-value="_id"
              v-model="searchBy"
              label="Search By"
              clearable
            ></v-select>
          </v-col>

          <v-col
            :xl="isReportFilter ? 3 : 2"
            :md="isReportFilter ? 3 : 2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-select
              dense
              class="mx-2"
              v-model="orderBy"
              :items="searchByOrder"
              item-text="name"
              item-value="orderBy"
              label="Order by"
            ></v-select>
          </v-col>

          <v-col
            :xl="isReportFilter ? 3 : 1"
            :md="isReportFilter ? 3 : 1"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-select
              dense
              class="mx-2"
              v-model="membershipPlan"
              :disabled="membershipPlanFilters.length === 0"
              :loading="!isMemberClassLoaded"
              :items="membershipPlanFilters"
              item-text="name"
              item-value="_id"
              label="Class"
              clearable
            ></v-select>
          </v-col>

          <v-col xl="2" md="2" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              clearable
              dense
              class="mx-2"
              v-model="genderType"
              :items="filterByGender"
              item-text="name"
              item-value="genderType"
              label="Gender"
            ></v-select>
          </v-col>

          <v-col xl="2" md="2" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              dense
              clearable
              class="mx-2"
              v-model="dueType"
              :items="filterByDues"
              item-text="name"
              item-value="dueType"
              label="Filter by dues"
            ></v-select>
          </v-col>

          <v-col
            :xl="isReportFilter ? 2 : 1"
            :md="isReportFilter ? 2 : 1"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-checkbox
              class="mx-2 pt-0 mt-2 with-dues-checkbox"
              v-model="showAdults"
              label="Show adults"
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import store from "@/store";
import { toRefs } from "@vue/composition-api";
import config from "@/config";
export default {
  props: ["isReportFilter"],
  setup(props, { root }) {
    return {
      ...(props.isReportFilter
        ? toRefs(store.membersReports.filters)
        : toRefs(store.members.filters)),
      menu: false,
      statusColors: { 1: "green", 2: "orange", 3: "grey" },
      addNewMember: () => {
        root._router.push({ name: "new_member" });
      },
      searchlabel: store.members.searchlabel,
      bloodTypes: config.bloodTypes
    };
  }
};
</script>
