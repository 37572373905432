<template>
  <v-col :sm="sm" :xs="xs" :md="md" :lg="lg" :cols="cols">
    <v-tooltip
      top
      max-width="250"
      nudge-top="-15"
      transition="fade-transition"
      open-delay="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card
          tile
          v-bind="attrs"
          v-on="on"
          :disabled="member.status === 3"
          :class="[
            'member-card',
            `gender-${member.gender}`,
            member.hasPhoto && 'has-photo',
            !member.active && 'removed'
          ]"
        >
          <div
            v-if="member.hasPhoto"
            :style="{ backgroundImage: `url(${member.photo})` }"
            class="photo"
          ></div>
          <v-card-text>
            <div class="force-single-line">
              <span>#{{ member.memberNumber }}</span>
              <span v-if="!member.active">
                <span class="error--text"> Removed </span>
                <span v-if="member.removedReason && member.removedReason != ''">
                  (Reason: {{ member.removedReason }})
                </span>
              </span>
            </div>

            <router-link :to="`/members/${member._id}`">
              <v-icon
                class="float-right pointer ml-2"
                size="20"
                color="primary"
              >
                mdi-dots-vertical
              </v-icon>
            </router-link>

            <v-icon
              class="float-right pointer"
              size="20"
              color="success"
              @click="showFeeWindow(member)"
            >
              mdi-cash-check
            </v-icon>
            <div class="text--primary force-single-line">
              {{ member.name }}
            </div>
            <div class="text--primary force-single-line">
              <b>{{ member.membershipPlan.name }}</b> class
            </div>
            <div class="text--primary force-single-line pr-15">
              {{ member.address }}
            </div>
            <div v-if="member.dues.dueAmount <= 0" class="force-single-line">
              <span v-if="member.dues.payedInAdvance">
                <span class="success--text">
                  Payed upto
                  {{ new Date(member.dues.payedUpto) | formatDateMonthAndYear }}
                </span>
                <span
                  v-if="!member.isMemberShipPlanFeesPaid"
                  class="warning--text"
                >
                  (Membership fee due ₹{{ member.membershipPlan.amount }})
                </span>
              </span>
              <span v-else> Settled all dues </span>
            </div>
            <div
              v-else-if="
                member.dues.dueAmount - member.membershipPlan.amount <= 0 &&
                  !member.isMemberShipPlanFeesPaid
              "
              class="force-single-line"
            >
              Settled all dues
              <span class="warning--text">
                (₹{{ member.membershipPlan.amount }} membership fee due)
              </span>
            </div>
            <div v-else class="warning--text force-single-line">
              ₹{{
                member.isMemberShipPlanFeesPaid
                  ? member.dues.dueAmount
                  : member.dues.dueAmount - member.membershipPlan.amount
              }}
              monthly dues
              <span
                v-if="!member.isMemberShipPlanFeesPaid"
                class="warning--text"
              >
                (₹{{ member.membershipPlan.amount }} membership fee due)
              </span>
            </div>
          </v-card-text>
        </v-card>
      </template>
      #{{ member.memberNumber }}, {{ member.name }}
    </v-tooltip>
  </v-col>
</template>

<script>
import store from "@/store";

const showFeeWindow = (member) => {
  let memberState = store.memberFeeWindow.state;
  memberState.member = { ...member };
  memberState.showWindow = true;
};

export default {
  props: ["lg", "md", "sm", "xs", "cols", "member"],
  setup(props) {
    return { showFeeWindow };
  }
};
</script>

<style lang="scss" scoped>
.member-card {
  .warning--text {
    font-size: 12px;
    padding-right: 40px;
  }
  &.gender-m::after {
    background-image: url("~@/assets/icons/male-member-bk-icon.svg");
  }
  &.gender-f::after {
    background-image: url("~@/assets/icons/female-member-bk-icon.svg");
  }
  &.gender-o::after {
    background-image: url("~@/assets/icons/other-member-bk-icon.svg");
  }
  &.has-photo::after {
    display: none;
  }
  .photo {
    background-size: cover;
    opacity: 1;
    border-radius: 50% !important;
    bottom: 20px;
  }
}
</style>
