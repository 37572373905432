var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":_vm.sm,"xs":_vm.xs,"md":_vm.md,"lg":_vm.lg,"cols":_vm.cols}},[_c('v-tooltip',{attrs:{"top":"","max-width":"250","nudge-top":"-15","transition":"fade-transition","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({class:[
          'member-card',
          ("gender-" + (_vm.member.gender)),
          _vm.member.hasPhoto && 'has-photo',
          !_vm.member.active && 'removed'
        ],attrs:{"tile":"","disabled":_vm.member.status === 3}},'v-card',attrs,false),on),[(_vm.member.hasPhoto)?_c('div',{staticClass:"photo",style:({ backgroundImage: ("url(" + (_vm.member.photo) + ")") })}):_vm._e(),_c('v-card-text',[_c('div',{staticClass:"force-single-line"},[_c('span',[_vm._v("#"+_vm._s(_vm.member.memberNumber))]),(!_vm.member.active)?_c('span',[_c('span',{staticClass:"error--text"},[_vm._v(" Removed ")]),(_vm.member.removedReason && _vm.member.removedReason != '')?_c('span',[_vm._v(" (Reason: "+_vm._s(_vm.member.removedReason)+") ")]):_vm._e()]):_vm._e()]),_c('router-link',{attrs:{"to":("/members/" + (_vm.member._id))}},[_c('v-icon',{staticClass:"float-right pointer ml-2",attrs:{"size":"20","color":"primary"}},[_vm._v(" mdi-dots-vertical ")])],1),_c('v-icon',{staticClass:"float-right pointer",attrs:{"size":"20","color":"success"},on:{"click":function($event){return _vm.showFeeWindow(_vm.member)}}},[_vm._v(" mdi-cash-check ")]),_c('div',{staticClass:"text--primary force-single-line"},[_vm._v(" "+_vm._s(_vm.member.name)+" ")]),_c('div',{staticClass:"text--primary force-single-line"},[_c('b',[_vm._v(_vm._s(_vm.member.membershipPlan.name))]),_vm._v(" class ")]),_c('div',{staticClass:"text--primary force-single-line pr-15"},[_vm._v(" "+_vm._s(_vm.member.address)+" ")]),(_vm.member.dues.dueAmount <= 0)?_c('div',{staticClass:"force-single-line"},[(_vm.member.dues.payedInAdvance)?_c('span',[_c('span',{staticClass:"success--text"},[_vm._v(" Payed upto "+_vm._s(_vm._f("formatDateMonthAndYear")(new Date(_vm.member.dues.payedUpto)))+" ")]),(!_vm.member.isMemberShipPlanFeesPaid)?_c('span',{staticClass:"warning--text"},[_vm._v(" (Membership fee due ₹"+_vm._s(_vm.member.membershipPlan.amount)+") ")]):_vm._e()]):_c('span',[_vm._v(" Settled all dues ")])]):(
              _vm.member.dues.dueAmount - _vm.member.membershipPlan.amount <= 0 &&
                !_vm.member.isMemberShipPlanFeesPaid
            )?_c('div',{staticClass:"force-single-line"},[_vm._v(" Settled all dues "),_c('span',{staticClass:"warning--text"},[_vm._v(" (₹"+_vm._s(_vm.member.membershipPlan.amount)+" membership fee due) ")])]):_c('div',{staticClass:"warning--text force-single-line"},[_vm._v(" ₹"+_vm._s(_vm.member.isMemberShipPlanFeesPaid ? _vm.member.dues.dueAmount : _vm.member.dues.dueAmount - _vm.member.membershipPlan.amount)+" monthly dues "),(!_vm.member.isMemberShipPlanFeesPaid)?_c('span',{staticClass:"warning--text"},[_vm._v(" (₹"+_vm._s(_vm.member.membershipPlan.amount)+" membership fee due) ")]):_vm._e()])],1)],1)]}}])},[_vm._v(" #"+_vm._s(_vm.member.memberNumber)+", "+_vm._s(_vm.member.name)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }