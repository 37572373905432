<template>
  <div style="height: 100%" class="members">
    <MembersFilters />
    <v-progress-linear v-if="isLargeLoading" indeterminate></v-progress-linear>
    <v-container v-if="noRecordsFound" class="text-center py-10">
      No members found.
    </v-container>
    <div class="books-listing-parent" ref="scrollable" v-bar>
      <div>
        <v-container
          :class="[isEmpty ? 'd-none' : '', !loadedAll ? 'pull-up-margin' : '']"
        >
          <v-row>
            <MemberCard
              v-for="member in data"
              :key="member._id"
              :member="member"
              sm="6"
              xs="12"
              md="4"
              lg="4"
              cols="12"
            />
          </v-row>
        </v-container>
        <SkeltonLoader v-if="!loadedAll" cardHeight="142" />
      </div>
    </div>
    <MemberFeeWindow />
  </div>
</template>

<script>
import { useMembers } from "@/modules";
import MemberCard from "@/components/MemberCard";
import SkeltonLoader from "@/components/SkeltonLoader";
import MembersFilters from "@/components/MembersFilters";
import MemberFeeWindow from "@/components/MemberFeeWindow";

export default {
  setup: useMembers,
  components: {
    MemberCard,
    SkeltonLoader,
    MembersFilters,
    MemberFeeWindow
  }
};
</script>
